import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';


const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/kyc-error',
  //   name: 'KYC Error',
  //   component: () => import('../views/user/___KYCError.vue')
  // },
  {
    path: '/blso',
    name: 'BLSO',
    component: () => import('../views/user/STO.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/user/404.vue'),
  },
  {
    path: '/investments',
    name: 'Investments',
    component: () => import('../views/user/Investments.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
  },
  {
    path: '/reset-password',
    name: 'Reset password',
    component: () => import('../views/auth/ResetPassword.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue'),
  },
  {
    path: '/already-verified',
    name: 'Verified',
    component: () => import('../views/auth/Verified.vue'),
  },
  {
    path: '/verify-success',
    name: 'Success',
    component: () => import('../views/auth/Success.vue')
  },
  {
    path: '/new-password',
    name: 'New Password',
    component: () => import('../views/auth/NewPassword.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/user/Settings.vue'),
  },
  // {
  //   path: '/bank-accounts',
  //   name: 'Bank Accounts Router',
  //   component: () => import('../views/user/BankAccountsRoute.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'Bank Accounts',
  //       component: () => import('../views/user/BankAccounts.vue'),
  //     },
  //     {
  //       path: 'create',
  //       name: 'Create Bank Account',
  //       component: () => import('../views/user/CreateBankAccount.vue'),
  //     },
  //     {
  //       path: ':id/edit',
  //       name: 'Edit Bank Account',
  //       component: () => import('../views/user/EditBankAccount.vue'),
  //     },
  //   ]
  // },
  // {
  //   path: '/deposits',
  //   name: 'Deposits',
  //   component: () => import('../views/user/Deposits.vue'),
  // },

  // {
  //   path: '/withdraw',
  //   name: 'Withdraw',
  //   component: () => import('../views/user/Withdraw.vue'),
  // },
  // {
  //   path: '/withdraw/:currencyCode/',
  //   name: 'Withdraw Create',
  //   component: () => import('../views/user/CreateWithdraw.vue'),
  // },
  // {
  //   path: '/withdrawal-wallets',
  //   name: 'Withdrawal Wallets Route',
  //   component: () => import('../views/user/WithdrawalWalletsRoute.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'Withdrawal Wallets',
  //       component: () => import('../views/user/WithdrawalWallets.vue'),
  //     },
  //     {
  //       path: 'create',
  //       name: 'Create Withdrawal Wallets',
  //       component: () => import('../views/user/CreateWithdrawalWallets.vue'),
  //     },

  //   ]
  // },

];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

export default router;
