import enUS from './langs/en-US.json';
import ptPT from './langs/pt-PT.json';
import ptBR from './langs/pt-BR.json';
import esES from './langs/es-ES.json';

export default {
    "en-US": enUS,
    "pt-PT": ptPT,
    "pt-BR": ptBR,
    "es-ES": esES
}