import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Maska from 'maska';
import Toaster from '@meforma/vue-toaster';
import { createI18n } from 'vue-i18n';
import locale from './i18n/locale.js';
import DropZone from 'dropzone-vue';
import 'dropzone/dist/basic.css';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

/* eslint-disable */

declare const window: any;

// route guarding
router.beforeEach((to, from, next) => {
    const userStatus = store.state.userData;
    store.dispatch('setCurrentRoute', to.path);
    if (to.path === '/') {
        next({ name: 'BLSO' });
    } else if (userStatus?.status === 4 && to.name !== 'Country Mismatch') {
        next({ name: 'Country Mismatch' });
    } else if (to.name === 'Country Mismatch' && userStatus?.status !== 4) {
        next({ name: 'Investments' });
    } else if (!router.hasRoute(to.name as any)) {
        next({ name: '404' });
    } else {
        next();
    }
});

//i18n
const messages = locale;

// 2. Create i18n instance with options
const i18n = createI18n({
    locale: window.localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE, // set locale
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE, // set fallback locale
    messages,
});


// 3. Create a vue root instance
// const app = Vue.createApp({
//     // set something options
//     // ...
// })

// 4. Install i18n instance to make the whole app i18n-aware
// app.use(i18n)

// 5. Mount
// app.mount('#app')

// init vue app
const app = createApp(App);

process.env.VUE_APP_ENVIRONMENT.match(/production|staging/) && Sentry.init({
    app,
    dsn: "https://a64c3e46269741dfa1488146608d4b3a@o993999.ingest.sentry.io/6061662",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "bezel-user.omniapps.com", /^\//],
        }),
    ],
    tracesSampleRate: 0.1,
});

app.use(Toaster).use(store).use(router).use(i18n).use(Maska).use(DropZone).mount('#app');