<template>
  <header class="header">
    <!--  -->
    <!-- v-if="type === 'regular'" -->
    <div class="w-100 px-4 d-flex justify-content-between align-items-center">
      <div
        class="
          posirtion-relative
          container
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <router-link to="/blso" class="logo me-4">
          <img src="@/assets/images/bezel-logo.png" alt="" />
        </router-link>

        <nav class="header-middle navigation">
          <router-link
            :class="currentRoute === '/blso' ? 'active' : ''"
            to="/blso"
            >{{ $t("BLSO") }}</router-link
          >
          <router-link
            :class="currentRoute === '/investments' ? 'active' : ''"
            to="/investments"
            >{{ $t("INVESTMENTS") }}</router-link
          >
          <router-link
            :class="currentRoute === '/settings' ? 'active' : ''"
            to="/settings"
            >{{ $t("SETTINGS") }}</router-link
          >
        </nav>
        <!-- <div class="btcPrice"></div> -->
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center" v-if="loggedIn">
            <div
              @mouseleave="showPopover = false"
              style="min-height: 89px"
              class="header-user-info-wrapper d-flex align-items-center"
            >
              <nav
                @mouseenter="showPopover = true"
                class="
                  d-flex
                  header-user-info
                  align-items-center
                  flex-nowrap
                  position-relative
                "
                :class="isActive ? 'nav active' : 'nav'"
              >
                <div class="user position-relative">
                  <img
                    @click="openUserMenu"
                    class="open-dropdown"
                    src="@/assets/images/user.svg"
                    alt=""
                  />
                </div>
                <p v-if="userData" class="ms-3 mb-0">
                  {{ userData.email }}
                </p>
                <div
                  @mouseleave="showPopover = false"
                  v-if="showPopover"
                  class="user-popover"
                >
                  <h4>{{ $t("ACCOUNT_INFO") }}</h4>
                  <p>{{ $t("CURRENTLY_SIGNED_IN_AS") }}</p>
                  <p class="blue-text">{{ userData.email }}</p>
                  <button @click="logout" class="button w-100">
                    {{ $t("SIGN_OUT") }}
                  </button>
                </div>
              </nav>
            </div>
          </div>
          <nav v-else class="header-auth-btns">
            <router-link
              to="/login"
              class="button"
              :class="active === 'login' && active"
              >{{ $t("SIGN_IN") }}</router-link
            >
            <router-link class="button white" to="/register">{{
              $t("REGISTER")
            }}</router-link>
          </nav>
          <div class="d-flex align-items-center">
            <div
              @mouseleave="showLangSelect = false"
              @mouseenter="showLangSelect = true"
              class="language-select-wrapper h-100 position-relative"
            >
              <div class="language-select-btn">
                <img :src="globeIcon" />
              </div>
              <div
                :class="showLangSelect ? 'active' : ''"
                class="language-select-dropdown"
              >
                <ul>
                  <li @click="setLanguage('en-US')">English</li>
                  <li @click="setLanguage('pt-PT')">Portugese</li>
                  <li @click="setLanguage('es-ES')">Spanish</li>
                </ul>
              </div>
            </div>
            <span
              :class="showMobileMenu ? 'navicon active' : 'navicon'"
              @click="handleOpenMobileMenu"
            >
              <i></i>
              <i></i>
              <i></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div :class="showMobileMenu ? 'active' : ''" class="mobile-menu">
      <!-- <p @click="showMobileMenu = false" class="close">✕</p> -->
      <div class="d-flex flex-column align-items-center">
        <router-link @click="closeMobileMenu" to="/blso">{{
          $t("BLSO")
        }}</router-link>
        <router-link @click="closeMobileMenu" to="/investments">{{
          $t("INVESTMENTS")
        }}</router-link>
        <router-link @click="closeMobileMenu" to="/settings">{{
          $t("SETTINGS")
        }}</router-link>
        <template v-if="!loggedIn">
          <router-link
            class="button"
            @click="closeMobileMenu"
            to="/login"
            >{{ $t("SIGN_IN") }}</router-link
          >
          <router-link
            class="button white"
            @click="closeMobileMenu"
            to="/register"
            >{{ $t("REGISTER") }}</router-link
          >
        </template>
        <div v-if="loggedIn && userData && userData.email" class="mt-5">
          <span class="d-inline-block mb-4 blue-text">{{
            userData.email
          }}</span>
          <button @click="logout" class="mt-2 button w-100">
            {{ $t("SIGN_OUT") }}
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="js">
import { defineComponent } from "vue";
import copyIcon from '../assets/images/copy.svg';
import redirect from '../assets/images/redirect.svg';
import config from '../config.js';
import { shortenAddress } from '../helpers.js';
import globeIcon from '../assets/images/globe.svg';

export default defineComponent({
	name: "Header",
	data: function () {
    return {
      showLangSelect: false,
      htmlBody: document.querySelector('body'),
      isActive: false,
      userMenuOpen: false,
      showAccInfoModal: false,
      copyIcon: copyIcon,
      redirect: redirect,
      wrongNetwork: false,
      balances:[],
      accessToken:null,
      shortenAddress: shortenAddress,
      loggedIn: false,
      userData: null,
      currentRoute: null,
      showPopover: false,
      showMobileMenu: false,
      resizeListener: null,
      globeIcon: globeIcon
    };
  },
  components: {
    // WalletModal,
  },
  props: {
    type: {
      type: String,
      required: false,
      // If not required, it's possible to give a default value
      default: "regular",
    },
  },
  mounted() {
    this.htmlBody.style.overflow = 'auto';
    window.addEventListener('resize', this.closeMobileMenu);
    console.log('header type prop??? ', this.type);
    this.$store.watch(state => {
      this.userData = state.userData;
      this.accessToken = state.accessToken;
      this.loggedIn = state.loggedIn;
      this.currentRoute = state.currentRoute;
      if(this.accessToken && this.type !== 'login') {
        this.getBalances();
      }
    });

    this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'refreshBalances') {
            this.getBalances();
        }
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.closeMobileMenu);
  },
  methods: {
    async setLanguage(lang) {
      await window.localStorage.setItem('locale', lang);
      window.location.reload();
    },
    handleOpenMobileMenu: function() {
      if (!this.showMobileMenu) {
        this.showMobileMenu = true;
        this.htmlBody.style.overflow = 'hidden';
      } else {
        this.showMobileMenu = false;
        this.htmlBody.style.overflow = 'auto';
      }
    },
    closeMobileMenu() {
      this.htmlBody.style.overflow = 'auto';
      this.showMobileMenu = false;
      // if (window.innerWidth > 991) {
      //   this.htmlBody.style.overflow = 'auto';
      // }
    },
    async logout() {
        this.closeMobileMenu();
        this.showPopover = false;
        this.showMobileMenu = false;
        await window.localStorage.setItem('accessToken', '');
        this.$store.dispatch('setLoggedIn', false);
        this.$store.dispatch('setAccessToken', null);
        this.$router.push('/login');
    },
     getBalances() {
        // fetch(`${config.API}/api/v1/balances`, {
        //     method: 'GET',
        //     headers: {
        //         ...config.defaultHeaders,
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //         'Authorization': `Bearer ${this.accessToken}`
        //     },
        // })
        // .then(res => res.json())
        // .then(data => {
        //     console.log(data);
        //     this.balances = data?.data?.balances;
        // })
        // .catch(error => {
        //     console.log(error);
        // })
    },
    copyAddress() {
        navigator.clipboard.writeText(this.metamaskAddress);
        this.$toast.success(this.$t('COPIED'), {position: 'top'});
    },
    openUserMenu() {
      this.userMenuOpen = !this.userMenuOpen;
    },
    closeModal() {
        this.showAccInfoModal = false;
    }
  },
});
</script>
