<template>
  <custom-header  :type="loggedIn ? 'regular' : 'login'" />
  <router-view />
  <custom-footer v-if="!loading" />
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import config from './config';

export default {
  name: "app",
  data: function() {
      return {
          loggedIn: false,
          loading: true,
      }
  },
  components: {
    "custom-header": Header,
    "custom-footer": Footer,
  },
  beforeCreate: function() {
    // check if access token available in 
    const accessToken = window.localStorage.getItem('accessToken');
    // check if access token is still valid, autologin
    if (!accessToken) {
        this.$store.dispatch('setLoggedIn', false);
        this.$store.dispatch('setGlobalLoading', false);
    } else {
        fetch(`${config.API}/api/v1/check-access-token`, {
            headers: {
                ...config.defaultHeaders,
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(async data => {
            console.log('dataaaaaaaa', data);
            if (data.message && data.message === 'Unauthenticated.') {
                this.$store.dispatch('setLoggedIn', false);
                this.$store.dispatch('setAccessToken', null);
                window.localStorage.setItem('accessToken', '');
                this.$store.dispatch('setGlobalLoading', false);
                // this.$router.push('/');
            } else {
                this.$store.dispatch('setUserData', data.data.user);
                this.$store.dispatch('setAccessToken', accessToken);
                this.$store.dispatch('setGlobalLoading', false);
                this.$store.dispatch('setLoggedIn', true);
                // this.$store.dispatch('setHasMinDeposit', data.data.has_minimum_deposited_funds);
                
                if (data.data.user.status === 4 || data.data.user.status === 3) {
                    this.$store.dispatch('setCountryMistamtch', true);
                    this.$router.push({name: 'KYC Error', params: {status: data.data.user.status}})
                }
            }
        });
    }
  },
  mounted: function () {
    this.$store.watch(state => {
        this.loggedIn = state.loggedIn;
        this.loading = state.globalLoading;
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/stylesheet.scss";
</style>
