import { createStore } from 'vuex'

export default createStore({
  state: {
    loggedIn: true,
    totalBalance: undefined,
    transactions: [],
    accessToken: '',
    globalLoading: true,
    refreshBalances: false,
    userData: null,
    kycStatus: null,
    countryMismatch: false,
    currentRoute: '',
    hasMinDeposit: true,
    kycComment: null
  },
  mutations: {
    setTotalBalance(state, newValue) {
      state.totalBalance = newValue;
    },
    setTransactions(state, newValue) {
      if (!newValue.length) {
        state.transactions = newValue;
      } else {
        state.transactions = state.transactions.concat(newValue);
      }
    },
    setLoggedIn(state, newValue) {
      state.loggedIn = newValue;
    },
    setAccessToken(state, newValue) {
      state.accessToken = newValue;
    },
    setGlobalLoading(state, newValue) {
      state.globalLoading = newValue;
    },
    refreshBalances(state, newValue) {
      state.refreshBalances = newValue;
    },
    setUserData(state, newValue) {
      state.userData = newValue;
    },
    setKycStatus(state, newValue) {
      state.kycStatus = newValue;
    },
    setCountryMistamtch(state, newValue) {
      state.countryMismatch = newValue;
    },
    setCurrentRoute(state, newValue) {
      state.currentRoute = newValue;
    },
    setHasMinDeposit(state, newValue) {
      state.hasMinDeposit = newValue;
    },
    setKycComment(state, newValue) {
      state.kycComment = newValue;
    }
  },
  actions: {
    setTotalBalance({ commit }, newValue) {
      commit('setTotalBalance', newValue);
    },
    setTransactions({ commit }, newValue) {
      commit('setTransactions', newValue);
    },
    setLoggedIn({ commit }, newValue) {
      commit('setLoggedIn', newValue);
    },
    setAccessToken({ commit }, newValue) {
      commit('setAccessToken', newValue);
    },
    setGlobalLoading({ commit }, newValue) {
      commit('setGlobalLoading', newValue);
    },
    refreshBalances({ commit }, newValue) {
      commit('refreshBalances', newValue);
    },
    setUserData({ commit }, newValue) {
      commit('setUserData', newValue);
    },
    setKycStatus({ commit }, newValue) {
      commit('setKycStatus', newValue);
    },
    setCountryMistamtch({ commit }, newValue) {
      commit('setCountryMistamtch', newValue);
    },
    setCurrentRoute({ commit }, newValue) {
      commit('setCurrentRoute', newValue);
    },
    setHasMinDeposit({ commit }, newValue) {
      commit('setHasMinDeposit', newValue);
    },
    setKycComment({ commit }, newValue) {
      commit('setKycComment', newValue);
    }
  }
});


